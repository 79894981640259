<template>
  <section id="ReportIndex">
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h1>Relatórios</h1>
          <h2>Relatório Único</h2>
        </v-flex>
      </v-layout>
    </app-page-title>
    <v-container>
      <div class="d-flex align-content-stretch detail-wrapper">
        <div id="reportViewer1"> Please wait, your report is loading...</div>
      </div>
    </v-container>
  </section>
</template>
<script>
/* eslint-disable prettier/prettier */
import {
  REPORTING_URL,
  REPORTING_KENDO,
  REPORTING_SERVICE_URL,
  // REPORTING_SERVER_URL,
  REPORTING_SERVER_USERNAME,
  REPORTING_SERVER_PASSWORD,
} from "@/settings";
export default {
  data () {
    return {
      name: "unique-report",
    };
  },
  mounted () {
    this.loadScripts([
      REPORTING_KENDO,
      REPORTING_URL,
    ]).then(() => {
      setTimeout(() => {
        this.initializeTelerikReportViewer();
      }, 1200);
    }).catch(() => {
      // console.error("Error loading scripts:", error);
    });
  },
  methods: {
    loadScript (src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    loadScripts (scripts) {
      const promises = scripts.map(this.loadScript);
      return Promise.all(promises);
    },
    initializeTelerikReportViewer () {
      $("#reportViewer1").telerik_ReportViewer({
        reportServer: {
          url: "https://reporting.koonnect.me",
          // url: REPORTING_SERVER_URL,
          username: REPORTING_SERVER_USERNAME,
          password: REPORTING_SERVER_PASSWORD,
        },
        serviceUrl: REPORTING_SERVICE_URL,
        reportSource: {
          report: "APDP/Relatorio-Unico",
        },
        viewMode: telerikReportViewer.ViewModes.INTERACTIVE,
        scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
        scale: 1.0
      });
    },
  },
};
</script>
<style scoped>
#reportViewer1 {
  min-height: 650px;
  position: relative;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}
</style>
